import React from 'react';
import {
    Link
  } from "react-router-dom";
import { useContext } from 'react';
import MenuContext from '../contexts/MenuContext';
const Footer = (props) => {
    const {setMenuSelect} = useContext(MenuContext)

    const handleClick = (path) => {
        setMenuSelect(path)
    }
    return(
        <footer>
        <div id="service">
            <div className="wrapper">
                <div id="footer-epse">
                    <div className="container-logos">
                        <div className="footer-logo">
                            <img src="/images/epse_footer.svg" alt="logo EPSE" />
                        </div>
                        <div className="footer-logo">
                            <img src="/images/logo-fwb-negatif.png" alt="logo FWB" />
                        </div>
                        <div className="footer-logo">
                            <img src="/images/eps_logo_blanc1.png" alt="logo EPS" />
                        </div>
                        <div className="footer-logo">
                            <img src="/images/fse-v.jpg" alt="logo FSE" />
                        </div>

                    </div>
                  <p>
                    L'EPSE est un établissement de promotion sociale reconnu et subsidié par la Fédération Wallonie-Bruxelles; membre de la Fédération des Etablissements Libres Subventionnés Indépendants  
                  </p>  
                </div>
                <div id="footer-ecole">
                    <h2>L'ÉCOLE</h2>
                    <Link to="/a-propos" onClick={()=>handleClick('a-propos')}>A propos</Link>
                    <a href="AGCF_2_septembre_2015_secondaire_supérieur_type_court.pdf">Règlements</a>
                    <Link to="/emploi" onClick={()=>handleClick('emploi')}>Emploi</Link>
                </div>
                <div id="footer-autres">
                    <h2>AUTRES</h2>
                    <a href="https://webmail.webmo.fr/">WebMail</a>
                    <a href="https://moodle.epse.be/login/index.php">Intranet</a>
                    <Link to="/qualite" onClick={()=>handleClick('qualite')}>Qualité</Link>
                    <Link to="/legal" onClick={()=>handleClick('legal')}>Légal</Link>
                </div>
                <div id="footer-social">
                    <h2>SUIVEZ-NOUS</h2>
                    <a className='social-link' href="https://www.linkedin.com/school/epse/" target="_blank"><img src="/images/linked.svg" alt="EPSE LinkedIn" /></a>
                    <a className='social-link' href="https://www.facebook.com/EPSEMarcq/" target="_blank"><img src="/images/facebook.svg" alt="EPSE Facebook" /></a>
                    <a className='social-link' href="https://vimeo.com/epsenghien" target="_blank"><img src="/images/vimeo.svg" alt="EPSE Vimeo" /></a>
                    <a className='social-link' href="https://www.youtube.com/channel/UC33Fs4BdaHlEb5GfDoZzSsA?view_as=subscriber" target="_blank"><img src="/images/youtube.svg" alt="EPSE YouTube" /></a>
                    <a className='social-link' href="https://www.instagram.com/e.p.s.e/" target="_blank"><img src="/images/insta.svg" alt="EPSE Instagram" /></a>
                    <Link to="/partenaires" className='partners-link'>Nos Partenaires</Link>
                </div>
                <div id="footer-contact">
                    <h2>CONTACT</h2>
                    <p><strong>Adresse:</strong><br />Rue du village, 50<br />7850 Marcq</p>
                    <p><strong>Tel:</strong> 02/395.60.23</p>
                    <p><strong>E-mail:</strong> info@epse.be</p>
                    <p><strong>IBAN:</strong> BE67 6352 1578 0187</p>
                </div>
            </div>
        </div>
        <div id="f-link">
           <div className="container">
                <div id="links"><Link to="/" onClick={()=>handleClick('home')}>Accueil</Link> | <Link to="/formations" onClick={()=>handleClick('Formations')}>Formations</Link> | <Link to="/inscription" onClick={()=>handleClick('inscription')}>Inscriptions</Link> | <Link to="/evenements" onClick={()=>handleClick('evenements')}>Evenements</Link> | <Link to="/informations" onClick={()=>handleClick('informations')}>Infos</Link> | <Link to="/actualites" onClick={()=>handleClick('actualites')}>Actus</Link> | <Link to="/contact" onClick={()=>handleClick('contact')}>Contact</Link> | <a href="https://moodle.epse.be/login/index.php">Intranet</a></div>
                <div id="copy">&copy; EPSE 2025 - Tous droits réservés</div>
           </div>
        </div>
    </footer>  
    );
}

export default Footer;